
.container {
    /* max-width: 1480px; */
    max-width: 90%;
    margin: 2rem auto;
    padding: 10px;
    font-family: Arial, sans-serif;
    background: #ffffff;

    /* width: 90%;
    max-width: 600px;
    margin: 0 auto; */
  
    overflow: hidden;
    border: 1px  #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
    transition: transform 0.3s, box-shadow 0.3s; 
  }

.containerdir {
    max-width: 100%;
    margin: 2rem auto;
    padding: 10px;
    font-family: Arial, sans-serif;
    background: #ffffff;
    overflow: hidden;
    border: 1px  #ffffff;
   
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

   
  }
  
  .title {
    font-size: 20px;
    padding-left: 10px;
    /* font-size: 18px; */
    font-weight: 600;
    color: #000000de;
    font-family: "AvertaRegular" !important;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em
  }
  


  .downloadButton {
    background-color: #221c53;
    border: 1px solid #221c53;
    color: white;
    padding: 6px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Updated shadow for more depth */
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  


  
  .downloadButton:hover {
    background-color: transparent; /* Changed to transparent for outline effect */
    color: #221c53; /* Retained text color */
    border: 1px solid #221c53; /* Added border for outline */
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
  
  .card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
   
  }
  
  .item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .item:last-child {
    border-bottom: none;
  }
  
  .label {
    color: #666;
    font-size: 0.875rem;
    flex: 1;
    padding-right: 1rem;
  }
  
  .value {
    color: #333;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: right;
    flex: 1;
  }
  
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  
    .title {
      font-size: 1.5rem;
    }
  
    .downloadButton {
      width: 100%;
      justify-content: center;
    }
  
    .grid {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 480px) {
    .container {
      margin: 1rem auto;
    }
  
    .item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .label {
      margin-bottom: 0.25rem;
    }
  
    .value {
      text-align: left;
    }
  }
  
  


























/* .tableContainer {
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    border: 1px  #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
    transition: transform 0.3s, box-shadow 0.3s; 
}
.tableContainer-dir {
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    border: 1px  solid #8e8e8e;
    
}

.headerContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  
 
}

  
  .heading {
    background-color: #ffffff;
    color: #333;
    font-size: 18px;
    font-weight: 600;
   padding-bottom: 10px;
    margin: 0;
   
  }
  
  .mobileCard {
    width: 100%;
    background: white;
  }

  .mobileCarddirector{
    width: 100%;
    background: white;
    border: 1px solid gray;
  }
  
  .infoRow {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
  }
  
  .label {
    color: #666;
    font-size: 14px;
    font-weight: 500;
  }
  
  .value {
    color: #333;
    font-size: 14px;
    font-weight: 600;
    text-align: right;
  }
  
  .buttonRow {
    padding: 16px;
    display: flex;
    justify-content: center;
    background: #f8f9fa;
  }
  
  .button {
    background: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 100%;
  }
  
  .button:hover {
    background: #0056b3;
  }
  
  .button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  

  @media (min-width: 768px) {
    .tableContainer {
      max-width: 100%;
    }
  
    .mobileCard {
      margin-bottom: 16px;
    }
  
    .buttonRow {
      justify-content: flex-end;
    }
  
    .button {
      width: auto;
    }
  }
  
  





 */
