.table-container {
    max-width: 90%;
    margin: 20px auto;
    background: #ffffff;
    padding: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Updated shadow for more depth */
    transition: transform 0.3s, box-shadow 0.3s; 
  }
  
  .table-scroll-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  @media (max-width: 768px) {
    .collapsible-table {
      min-width: 650px; /* Adjust this value based on your table's content */
    }
  }
  
  .main-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .main-table-title {
    font-size: 20px;
    color: #000000de;
    font-family: "AvertaRegular" !important;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em
  }
  .main-table-heading {
    font-weight: 600;
    color: #000000de;
    font-family: "AvertaRegular" !important;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 0.0075em
  }
  .main-table-data {
    font-weight: 500;
    color: #000000de;
    font-family: "AvertaRegular" !important;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 0.0075em
  }
  
  .collapsible-table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Inter', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
  }
  
  .collapsible-table th,
  .collapsible-table td {
    padding: 12px 16px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .collapsible-table th {
    background-color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    color: #333;
  }
  
  .collapsible-table tr:last-child td {
    border-bottom: none;
  }
  
  .collapsible-table tr:nth-child(even) {
    background-color: #ffffff;
  }
  
  .actions-column {
    text-align: center;
    width: 100px;
  }
  
  .details-button {
    background-color: #221c53;
    border: 1px solid #221c53;
    color: white;
    padding: 6px 12px;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Updated shadow for more depth */
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .details-button:hover:not(:disabled) {
    background-color: transparent; /* Changed to transparent for outline effect */
    color: #221c53; /* Retained text color */
    border: 1px solid #221c53; 
  }
  
  .details-button:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  .details-button .icon {
    margin-left: 4px;
    width: 14px;
    height: 14px;
  }
  
  .animate-spin {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .details-row {
    background-color: #ffffff;
  }
  
  .details-content {
    padding: 16px;
    animation: fadeIn 0.3s ease;
  }
  
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .expanded {
    background-color: #ffffff;
  }
  
  .detail-table-wrapper {
    margin-bottom: 20px;
  }
  
  .detail-table-wrapper:last-child {
    margin-bottom: 0;
  }
  
  .detail-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  
  .details-title {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin: 0;
  }
  
  .download-button {
    background-color: #221c53;
    border: 1px solid #221c53;
    color: white;
    padding: 6px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Updated shadow for more depth */
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .download-button:hover {
    background-color: transparent; /* Changed to transparent for outline effect */
    color: #221c53; /* Retained text color */
    border: 1px solid #221c53; /* Added border for outline */
  }
  
  .download-button svg {
    margin-right: 6px;
  }
  
  .details-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
  }
  
  .details-table th,
  .details-table td {
    border: 1px solid #e0e0e0;
    padding: 10px 12px;
    text-align: left;
  }
  
  .details-table th {
    background-color: #ffffff;
    font-weight: 600;
    font-size: 12px;
    color: #333;
  }
  
  .details-table tr:nth-child(even) {
    background-color: #ffffff;
  }
  
  @media (max-width: 768px) {
    .collapsible-table th,
    .collapsible-table td,
    .details-table th,
    .details-table td {
      padding: 8px 12px;
      font-size: 12px;
    }
  
    .actions-column {
      width: auto;
    }
  
    .details-button,
    .download-button {
      padding: 4px 8px;
      font-size: 11px;
    }
  
    .main-table-header,
    .detail-table-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .main-table-title,
    .details-title {
      margin-bottom: 8px;
    }
  }
  
  @media (max-width: 480px) {
    .main-table-header,
    .detail-table-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .main-table-title,
    .details-title {
      margin-bottom: 8px;
    }
  
    .download-button {
      width: 100%;
      justify-content: center;
    }
  }
  
  